import { useEffect } from 'react'
import { ChangeEvent, FormEvent, useState } from 'react'
import styled from 'styled-components'

import { Box } from 'ui-primitives/src/Box'
import { Spinner } from 'ui-primitives/src/Spinner'
import { spacing } from 'ui-primitives/src/utils/spacing'
import { Disclaimer } from 'bl-common/src/booking/Disclaimer/Disclaimer'
import {
  ProgressButton,
  ProgressButtonProps,
} from 'bl-common/src/elements/ProgressButton/ProgressButton'
import { StyledInput } from 'bl-common/src/form/Input/Input'
import * as styles from 'bl-common/src/units/Form/styles'

import {
  cardNumberContainer,
  cvvContainer,
  defaultFormStrings,
  expiryDateId,
  initialInputStyle,
} from './config'
import { Field } from './Field'
import { SecureFieldsIframe } from './Iframe'
import { SecureFieldsOptions } from './types'
import { FormStrings, Iframe3dsData, MappedCallbacks } from './types'
import { useIframeMessageHandler } from './useIframeMessageHandler'
import { useSecureFields } from './useSecureFields'
import { useSecureFieldsEvents } from './useSecureFieldsEvents'

const formatExpiryDate = (value: string) => {
  const val = value.replace(/\D/g, '')

  return val.replace(/(\d{2})(\d{0,4})/, '$1 / $2')
}

const StyledForm = styled.form({
  width: '100%',
})

const GridContainer = styled.div({
  width: '100%',
  display: 'grid',
  gap: spacing[1.5],
  gridTemplateColumns: '1fr 1fr',
})

const GridItem = styled.div(({ column }: { column?: number }) => ({
  gridColumn: column || 'auto',
}))

const initialStyles = {
  cardNumber: initialInputStyle(),
  cvv: initialInputStyle(),
  '*::placeholder': 'opacity: 0.5;',
  '*': 'font-family: DINPro, Helvetica, sans-serif;',
  '@font-face': {
    '*': {
      fontFamily: 'DINPro',
      fontStyle: 'normal',
      fontWeight: 400,
      src: "url('DINPro-Regular.woff2') format('woff2')",
    },
  },
}

type PaymentThemeStyles = {
  input?: {
    borderRadius?: number
    backgroundColor?: string
    borderColor?: string
    hoverBorderColor?: string
    textColor?: string
  }
  button?: {
    style?: React.ComponentProps<typeof ProgressButton>['style']
    preset?: ProgressButtonProps['preset']
  }
}

export function SecureFieldsForm({
  transactionId,
  formStrings: passedInFormStrings,
  options,
  callbacks,
  on3dsStatusChange,
  disclaimer,
  themeStyles,
}: {
  transactionId: string
  formStrings?: Partial<FormStrings>
  options?: SecureFieldsOptions
  callbacks?: MappedCallbacks
  on3dsStatusChange?: (data: Iframe3dsData) => void
  disclaimer?: {
    text?: string
    textColor?: string
    color?: string
  }
  themeStyles?: PaymentThemeStyles
}) {
  const formStrings = {
    ...defaultFormStrings,
    ...passedInFormStrings,
    cardNumber: {
      ...defaultFormStrings.cardNumber,
      ...passedInFormStrings?.cardNumber,
    },
    cvv: {
      ...defaultFormStrings.cvv,
      ...passedInFormStrings?.cvv,
    },
    expiryDate: {
      ...defaultFormStrings.expiryDate,
      ...passedInFormStrings?.expiryDate,
    },
  }

  const secureFields = useSecureFields({
    transactionId,
    fields: {
      cardNumber: {
        placeholderElementId: cardNumberContainer,
        inputType: 'tel',
        placeholder: formStrings.cardNumber.placeholder,
      },
      cvv: {
        placeholderElementId: cvvContainer,
        inputType: 'tel',
        placeholder: formStrings.cvv.placeholder,
      },
    },
    options: {
      styles: {
        ...initialStyles,
        ...options?.styles,
      },
    },
  })
  const { successData, fieldErrors, isLoading, focusedField, paymentMethod } =
    useSecureFieldsEvents({
      secureFields,
      callbacks,
      formStrings,
      transactionId,
    })

  const [expiryDate, setExpiryDate] = useState('')
  const [expiryError, setExpiryError] = useState<string | null>(null)
  const [submitStatus, setSubmitStatus] = useState<
    'initial' | 'submitting' | 'complete'
  >('initial')

  useEffect(() => {
    setSubmitStatus('initial')
  }, [transactionId])

  useEffect(() => {
    if (successData?.transactionId) {
      setSubmitStatus('complete')
    }
  }, [successData?.transactionId])

  useEffect(() => {
    if (fieldErrors.cardNumber || fieldErrors.cvv) {
      setSubmitStatus('initial')
    }
  }, [fieldErrors])

  useIframeMessageHandler<Iframe3dsData>(data => {
    if (data?.status && data?.transactionId) {
      on3dsStatusChange?.(data)
    }
  })

  const validateExpiryDate = () => {
    const sanitizedExpiryDate = expiryDate.replace(/\s+/g, '')
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/
    if (!sanitizedExpiryDate) {
      setExpiryError(formStrings.expiryDate.required)
      return false
    }
    if (!regex.test(sanitizedExpiryDate)) {
      setExpiryError(formStrings.expiryDate.invalid)
      return false
    }
    setExpiryError(null)
    return true
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (expiryError || fieldErrors?.cardNumber || fieldErrors?.cvv) {
      return
    }
    setSubmitStatus('submitting')
    const form = event.target as typeof event.target & {
      expiryDate: { value: string }
    }

    const [expm, expy] = form.expiryDate.value
      .split('/')
      .map(part => part.trim())

    validateExpiryDate()
    secureFields?.submit({ expm, expy })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value.trim()

    if (newValue.length > expiryDate.length) {
      newValue = formatExpiryDate(newValue)
    }

    setExpiryDate(newValue)
  }

  return (
    <>
      {isLoading && (
        <Box alignItems="center" justifyContent="center" flexDirection="row">
          <Spinner />
        </Box>
      )}
      <StyledForm
        onSubmit={handleSubmit}
        // We need to use css to hide the form because the form needs to be rendered for SecureFields to be able to initialize.
        style={{
          display: !isLoading && !successData?.redirect ? 'block' : 'none',
        }}
      >
        <Box gap={1.5}>
          <div>
            <Field
              fieldType={cardNumberContainer}
              paymentMethod={paymentMethod}
              label={formStrings.cardNumber.label}
              error={fieldErrors.cardNumber}
              isFocused={focusedField === 'cardNumber'}
              themeStyle={{
                defaultCardColor: themeStyles?.input?.textColor,
                labelColor: themeStyles?.input?.textColor,
                input: themeStyles?.input,
              }}
            />
          </div>

          <GridContainer>
            <GridItem column={1}>
              <styles.TopWrapper>
                <styles.Label
                  htmlFor={expiryDateId}
                  isRequired
                  color={themeStyles?.input?.textColor}
                >
                  {formStrings.expiryDate.label}*
                </styles.Label>
                <styles.ErrorMessage>{expiryError}</styles.ErrorMessage>
              </styles.TopWrapper>
              <StyledInput
                id={expiryDateId}
                value={expiryDate}
                placeholder={formStrings.expiryDate.placeholder}
                type="text"
                hasError={Boolean(expiryError)}
                aria-invalid={Boolean(expiryError)}
                aria-label={`${formStrings.expiryDate.label}, Required`}
                onChange={handleChange}
                autoComplete="cc-exp"
                onBlur={validateExpiryDate}
                disabled={isLoading}
                themeStyle={themeStyles?.input}
              />
            </GridItem>
            <GridItem column={2}>
              <Field
                fieldType={cvvContainer}
                label={formStrings.cvv.label}
                error={fieldErrors.cvv}
                isFocused={focusedField === 'cvv'}
                themeStyle={{ input: themeStyles?.input }}
              />
            </GridItem>
          </GridContainer>

          {disclaimer?.text && (
            <Box marginTop={1}>
              <Disclaimer
                textColor={disclaimer.textColor}
                color={disclaimer.color}
                preset="text"
              >
                {disclaimer.text}
              </Disclaimer>
            </Box>
          )}

          <ProgressButton
            type="submit"
            preset={themeStyles?.button?.preset ?? undefined}
            loading={submitStatus === 'submitting'}
            complete={submitStatus === 'complete'}
            display="block"
            paddingSize="large"
            top={{ xs: 1.5 }}
            bottom={{ xs: 0.5 }}
            style={{ width: '100%', ...themeStyles?.button }}
          >
            {formStrings.buttonLabel}
          </ProgressButton>
        </Box>
      </StyledForm>
      {successData?.redirect && (
        <SecureFieldsIframe src={successData.redirect} />
      )}
    </>
  )
}
