export const JCB = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="a"
    viewBox="62.91 1 135.72 87"
  >
    <defs>
      <style>
        {
          '.e{fill:#0e4c96;}.e,.f,.g,.h,.i{stroke-width:0px;}.f{fill:#fff;}.g{fill:url(#b);}.h{fill:url(#c);}.i{fill:url(#d);}'
        }
      </style>
      <linearGradient
        id="b"
        x1={-354.68}
        y1={-56.61}
        x2={-354.5}
        y2={-56.61}
        gradientTransform="translate(47270.86 18332.25) scale(132.87 323.02)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#007b40" />
        <stop offset={1} stopColor="#55b330" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-352.83}
        y1={-56.61}
        x2={-352.66}
        y2={-56.61}
        gradientTransform="translate(47171.98 18332.25) scale(133.43 323.02)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#1d2970" />
        <stop offset={1} stopColor="#006dba" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-353.75}
        y1={-56.63}
        x2={-353.58}
        y2={-56.63}
        gradientTransform="translate(47154.42 18336.36) scale(132.96 323.03)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#6e2b2f" />
        <stop offset={1} stopColor="#e30138" />
      </linearGradient>
    </defs>
    <path
      className="e"
      d="M69.87,1h121.8c3.84,0,6.96,3.12,6.96,6.96v73.08c0,3.84-3.12,6.96-6.96,6.96h-121.8c-3.84,0-6.96-3.12-6.96-6.96V7.96c0-3.84,3.12-6.96,6.96-6.96Z"
    />
    <path
      className="f"
      d="M172.92,63.86c0,7.24-5.87,13.11-13.11,13.11h-71.19V25.14c0-7.24,5.87-13.11,13.11-13.11h71.19s0,51.83,0,51.83h0Z"
    />
    <path
      className="g"
      d="M149.71,45.64c2.03.04,4.08-.09,6.1.07,2.05.38,2.55,3.49.72,4.5-1.24.67-2.72.25-4.07.37h-2.76v-4.94s0,0,0,0ZM156.99,40.04c.45,1.59-1.09,3.03-2.62,2.81h-4.66c.03-1.5-.06-3.14.05-4.56,1.87.05,3.75-.11,5.6.08.8.2,1.46.86,1.63,1.67h0ZM168.2,16.4c.09,3.05.01,6.25.04,9.36,0,12.63.01,25.26,0,37.9-.08,4.73-4.28,8.85-8.98,8.94-4.71.02-9.41,0-14.12,0v-19.1c5.13-.03,10.26.05,15.38-.04,2.38-.15,4.98-1.72,5.09-4.34.28-2.63-2.2-4.45-4.55-4.73-.9-.02-.88-.26,0-.37,2.24-.48,4.01-2.81,3.35-5.13-.56-2.45-3.27-3.39-5.52-3.39-4.59-.03-9.17,0-13.76-.01.03-3.57-.06-7.13.05-10.7.36-4.65,4.66-8.48,9.3-8.4h13.72s0,0,0,0Z"
    />
    <path
      className="h"
      d="M93.32,25.28c.12-4.73,4.33-8.81,9.03-8.88,4.69-.01,9.38,0,14.07,0-.01,15.81.03,31.63-.02,47.44-.18,4.67-4.35,8.67-8.99,8.75-4.7.02-9.4,0-14.09,0v-19.74c4.56,1.08,9.35,1.54,14,.82,2.78-.45,5.83-1.81,6.77-4.7.69-2.47.3-5.07.41-7.6v-5.89h-8.06c-.04,3.89.07,7.79-.06,11.68-.22,2.39-2.58,3.91-4.84,3.83-2.8.03-8.33-2.03-8.33-2.03-.01-7.29.08-16.43.12-23.7h0Z"
    />
    <path
      className="i"
      d="M119.41,37.87c-.42.09-.09-1.44-.19-2.03.03-3.68-.06-7.36.05-11.04.36-4.67,4.7-8.51,9.35-8.4h13.71c-.01,15.81.03,31.63-.02,47.44-.18,4.67-4.35,8.67-8.99,8.75-4.7.02-9.4,0-14.09,0v-21.63c3.21,2.63,7.57,3.04,11.57,3.05,3.01,0,6.01-.47,8.93-1.16v-3.96c-3.3,1.64-7.17,2.69-10.83,1.74-2.55-.64-4.4-3.1-4.36-5.73-.3-2.74,1.31-5.63,4-6.44,3.34-1.05,6.98-.25,10.11,1.11.67.35,1.35.79,1.08-.33v-3.11c-5.23-1.25-10.81-1.7-16.07-.35-1.52.43-3.01,1.08-4.24,2.08h0Z"
    />
  </svg>
)
