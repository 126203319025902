export type Datatrans = {
  startPayment: (options: {
    transactionId: string
    opened?: () => void
    loaded?: () => void
    closed?: () => void
    error?: () => void
  }) => void
}

interface FontFace {
  fontFamily: string
  src: string
  fontWeight: number
  fontStyle: string
}

interface SecureFieldStyles {
  cardNumber?: string | { [key: string]: FontFace }
  cvv?: string | { [key: string]: FontFace }
  [key: string]: string | { [key: string]: FontFace } | undefined
}

export type SecureFieldsOptions = {
  styles: SecureFieldStyles
}

export type SecureFieldsFieldsConfig = {
  cardNumber: {
    inputType: string
    placeholder?: string
    placeholderElementId: string
  }
  cvv: {
    inputType: string
    placeholder?: string
    placeholderElementId: string
  }
}

// Supported card types
export enum CardTypes {
  AMERICAN_EXPRESS = 'AMX',
  DINERS = 'DIN',
  JCB = 'JCB',
  MASTERCARD = 'ECA',
  VISA = 'VIS',
}

type CardInfoData = {
  cardInfo: {
    brand: string
    country: string
    issuer: string
    type: 'credit' | 'debit'
    usage: string
  }
  maskedCard: string
  paymentMethod: CardTypes
}

type OnCallbacks = {
  cardInfo: (data: CardInfoData) => void
  change: (data: SecureFieldsData) => void
  error: (data: SecureFieldsErrorData) => void
  ready: () => void
  success: (data: SecureFieldsSuccessData) => void
  validate: (data: SecureFieldsData) => void
}

export type SecureFields = {
  new (): SecureFields
  clear: () => void
  destroy: () => void
  focus: () => void
  getCardInfo: (callback: (data: CardInfoData) => void) => void
  init: (
    transactionId: string,
    fields: SecureFieldsFieldsConfig,
    options?: SecureFieldsOptions
  ) => void
  on: <T extends keyof OnCallbacks, C extends OnCallbacks[T]>(
    type: T,
    callback: C
  ) => void
  setStyle: (field: string, style: string) => void
  submit: ({ expm, expy }: { expm: string; expy: string }) => void
}

export type SecureFieldsData = {
  event: {
    field: 'cardNumber' | 'cvv'
    type: 'focus' | 'blur' | 'keyUp' | 'keyDown'
  }
  fields: {
    cardNumber?: {
      isExcluded?: boolean
      length: number
      paymentMethod?: CardTypes
      valid: boolean
    }
    cvv?: {
      length: number
      valid: boolean
    }
  }
  hasErrors: boolean
}

export type SecureFieldsSuccessData = {
  cardInfo: {
    brand: string
    country: string
    issuer: string
    type: string
    usage: string
  }
  redirect?: string
  transactionId: string
}

type SecureFieldsErrorData =
  | {
      action: string
      error: string
    }
  | string

type Iframe3dsStatus = 'success' | 'failure'
export type Iframe3dsData = {
  status: Iframe3dsStatus
  transactionId: string
  message: string
}

type CallbacksMap = {
  onSuccess: 'success'
  onReady: 'ready'
  onValidate: 'validate'
  onChange: 'change'
  onError: 'error'
}
export type MappedCallbacks = {
  [K in keyof CallbacksMap]?: OnCallbacks[CallbacksMap[K]]
}

type Strings = {
  required: string
  invalid: string
  label: string
  notSupported?: string
  placeholder?: string
}

export type FormStrings = {
  cardNumber: Partial<Strings>
  cvv: Partial<Strings>
  expiryDate: Partial<Strings>
  buttonLabel: string
}
