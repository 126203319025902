export const Mastercard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    viewBox="58.04 1.17 140.47 86.83"
  >
    <defs>
      <style>
        {
          '.b{fill:#ff5f00;}.b,.c,.d{stroke-width:0px;}.c{fill:#eb001b;}.d{fill:#f79e1b;}'
        }
      </style>
    </defs>
    <rect className="b" x={109.29} y={10.46} width={37.98} height={68.26} />
    <path
      className="c"
      d="M111.7,44.59c-.01-13.32,6.1-25.9,16.58-34.13-18.85-14.82-46.14-11.55-60.95,7.3-14.82,18.85-11.55,46.14,7.3,60.95,15.74,12.37,37.91,12.37,53.65,0-10.48-8.22-16.59-20.81-16.58-34.13Z"
    />
    <path
      className="d"
      d="M198.51,44.59c0,23.97-19.43,43.41-43.41,43.41-9.73,0-19.18-3.27-26.83-9.28,18.85-14.82,22.11-42.12,7.29-60.96-2.13-2.71-4.58-5.16-7.29-7.29,18.85-14.81,46.14-11.55,60.95,7.3,6.01,7.65,9.28,17.1,9.28,26.83Z"
    />
    <path
      className="d"
      d="M194.37,71.49v-1.4h.56v-.28h-1.44v.28h.56v1.4h.31ZM197.15,71.49v-1.68h-.44l-.51,1.16-.51-1.16h-.44v1.68h.31v-1.27l.47,1.1h.32l.47-1.1v1.27h.31Z"
    />
  </svg>
)
