export const Visa = ({ color = '#273990' }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    viewBox="3.4 17.15 195.72 59.86"
    fill={color}
  >
    <path d="M72.33,76.24h15.85l9.93-58.14h-15.85l-9.93,58.14ZM130.09,41.48c-5.54-2.67-8.97-4.49-8.88-7.16,0-2.39,2.86-4.96,9.07-4.96,5.16-.1,8.97,1.05,11.84,2.2l1.43.67,2.1-12.6c-3.15-1.15-8.02-2.48-14.23-2.48-15.66,0-26.73,7.92-26.83,19.19-.1,8.31,7.83,12.98,13.84,15.75,6.21,2.86,8.21,4.68,8.21,7.26,0,3.91-4.96,5.63-9.45,5.63-6.3,0-9.74-.86-14.89-3.06l-2-.95-2.2,12.98c3.72,1.62,10.5,3.06,17.66,3.06,16.61,0,27.5-7.83,27.59-19.86.1-6.49-4.2-11.55-13.27-15.66h0ZM186.33,18.19h-12.22c-3.82,0-6.59,1.05-8.31,4.77l-23.58,53.27h16.61s2.77-7.16,3.34-8.78h20.34c.48,2,1.91,8.69,1.91,8.69h14.7l-12.79-57.95h0ZM166.75,55.61c1.34-3.34,6.3-16.23,6.3-16.23-.1.19,1.34-3.34,2.1-5.54l1.05,5.06s3.06,13.84,3.63,16.8h-13.08v-.1ZM59.06,18.19l-15.56,39.62-1.62-8.02-5.54-26.73c-.95-3.72-3.72-4.77-7.16-4.87H3.59l-.19,1.15c6.21,1.53,11.74,3.72,16.61,6.4l14.13,50.5h16.8l24.92-58.05s-16.8,0-16.8,0Z" />
  </svg>
)
