import { useEffect } from 'react'

export const useIframeMessageHandler = <T>(callback: (data: T) => void) => {
  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent<T>) => callback(event.data)

    window.addEventListener('message', handleIframeMessage, false)

    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [callback])
}
