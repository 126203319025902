export const DefaultCard = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    viewBox="81.51 1 117.32 87.98"
    fill={color}
  >
    <path d="M189.06,1c2.59,0,5.08,1.03,6.91,2.86s2.86,4.32,2.86,6.91v68.44c0,2.59-1.03,5.08-2.86,6.91s-4.32,2.86-6.91,2.86h-97.78c-2.59,0-5.08-1.03-6.91-2.86s-2.86-4.32-2.86-6.91V10.78c0-2.59,1.03-5.08,2.86-6.91,1.83-1.83,4.32-2.86,6.91-2.86h97.78ZM193.94,40.11h-107.56v39.11c0,2.7,2.19,4.89,4.89,4.89h97.78c2.7,0,4.89-2.19,4.89-4.89v-39.11ZM120.61,64.56v4.89h-24.44v-4.89h24.44ZM184.17,54.78v4.89h-14.67v-4.89h14.67ZM135.28,54.78v4.89h-39.11v-4.89h39.11ZM86.39,25.44v9.78h107.56v-9.78h-107.56ZM193.94,20.56v-9.78c0-2.7-2.19-4.89-4.89-4.89h-97.78c-2.7,0-4.89,2.19-4.89,4.89v9.78h107.56Z" />
  </svg>
)
